/* global document */
import Global from './global';
import Oyster from './oyster';
import Marlin from './marlin';
import Home from './home';

document.addEventListener('DOMContentLoaded', () => {
  const global = new Global();
  global.init();
  const oyster = new Oyster();
  oyster.init();
  const marlin = new Marlin();
  marlin.init();
  const home = new Home();
  home.init();
});
