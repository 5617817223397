import gsap from 'gsap';
import ResizeObserver from 'resize-observer-polyfill';

window.ResizeObserver = ResizeObserver;

export default class Oyster {
  init() {
    this._tabs();
    this._accordion();
    this._tabsImage();
    this._imageCarousel();
  }

  _tabs() {
    let tabs = document.querySelectorAll('.tabs__tabs__item');
    let indicatorBar = document.querySelector('.tabs__tabs__indicator');
    let currentIndex = 0;
    let interval = 5000;
    let listAnimationTimeout;
    let isAllTab = document.querySelector("button[data-tab-all='true']");

    let tabsTl = gsap.timeline({});

    function changeTab() {
      let nextIndex = (currentIndex + 1) % tabs.length;
      if (!tabs[nextIndex]) return;
      if (document.hasFocus()) {
        tabs[nextIndex].click();
        currentIndex = nextIndex;
      } else {
        listAnimationTimeout = setTimeout(changeTab, interval);
      }
    }

    tabs.forEach(function (tab) {
      tab.addEventListener('click', function () {
        clearTimeout(listAnimationTimeout);
        let tabContent = document.querySelector(
          ".tabs__content__item[data-tab*='" +
            this.getAttribute('data-tab-id') +
            "']"
        );
        let nextIndex =
          (Number(this.getAttribute("data-tab-id")) - 1) % tabs.length;
        currentIndex = nextIndex;

        if (!this.classList.contains('is-active')) {
          // Assuming 'filterItem' and 'filteredItems' are previously defined
          tabs.forEach(function (otherTab) {
            otherTab.classList.remove('is-active');
          });
          this.classList.add('is-active');

          let tabsContent = document.querySelectorAll('.tabs__content__item');

          tabsTl
            .to(tabsContent, {
              duration: 0.3,
              alpha: 0,
              translateY: 10,
              ease: 'power3',
            })
            .set(tabsContent, {
              duration: 0,
              display: 'none',
              ease: 'power3',
            })
            .set(tabContent, {
              duration: 0,
              display: 'flex',
              ease: 'power3',
            })
            .set(tabContent, {
              duration: 0,
              display: 'flex',
              ease: 'power3',
            })
            .to(tabContent, {
              duration: 0.3,
              alpha: 1,
              translateY: 0,
              ease: 'power3',
            });
        }
        // Schedule the next opening
        listAnimationTimeout = setTimeout(changeTab, interval);

        indicator();
      });
    });

    indicator();

    function indicator() {
      let activeButton = document.querySelector('.tabs__tabs__item.is-active');
      if (!activeButton) return;
      let indicatorWidth = activeButton.offsetWidth;
      let indicatorOffset = activeButton.offsetLeft;

      indicatorBar.style.width = indicatorWidth + 'px';
      indicatorBar.style.transform = 'translateX(' + indicatorOffset + 'px)';
      indicatorBar.style.opacity = '1';
    }

    if (isAllTab) {
      listAnimationTimeout = setTimeout(changeTab, interval);
    }
  }


  _accordion() {
    let accordions = document.querySelectorAll('.accordion');

    accordions.forEach(function (accordion) {
      // let accordionItem = accordion.closest('.accordion');
      let accordionTrigger = accordion.querySelector('.accordion__trigger');
      let accordionContent = accordion.querySelector('.accordion__content');
      accordionTrigger.addEventListener('click', function () {
        let accordionTl = gsap.timeline();

        if (accordion.classList.contains('is-active')) {
          accordion.classList.remove('is-active');
          accordionTl.to(accordionContent, {
            duration: 0.3,
            height: 0,
            ease: 'power3',
          });
        } else {
          accordion.classList.add('is-active');
          accordionTl.to(accordionContent, {
            duration: 0.3,
            height: 'auto',
            ease: 'power3',
          });
        }
      });
    });
  }

  _tabsImage() {
    let tabs = document.querySelectorAll('.tabs-image__tabs__item');
    let indicatorBar = document.querySelector('.tabs-image__tabs__indicator');
    let tabsImageTl = gsap.timeline({});
    let currentIndex = 0;
    let interval = 5000;
    let listAnimationTimeout;

    function changeTab() {
      let nextIndex = (currentIndex + 1) % tabs.length;
      if (!tabs[nextIndex]) return;
      if (document.hasFocus()) {
        tabs[nextIndex].click();
        currentIndex = nextIndex;
      } else {
        listAnimationTimeout = setTimeout(changeTab, interval);
      }
    }

    tabs.forEach(function (tab) {
      tab.addEventListener('click', function () {
        clearTimeout(listAnimationTimeout);
        if (this.classList.contains('is-active')) {
          listAnimationTimeout = setTimeout(changeTab, interval);
          return;
        }
        let tabContentActive = document.querySelector(
          ".tabs-image__content__item[data-tab*='" +
            document
              .querySelector('.tabs-image__tabs__item.is-active')
              .getAttribute('data-tab-id') +
            "']"
        );
        let nextIndex =
          (Number(this.getAttribute("data-tab-id")) - 1) % tabs.length;
        currentIndex = nextIndex;

        // Remove and add 'is-active' class
        tabs.forEach(function (otherTab) {
          otherTab.classList.remove('is-active');
        });
        this.classList.add('is-active');

        let tabContent = document.querySelector(
          ".tabs-image__content__item[data-tab*='" +
            this.getAttribute('data-tab-id') +
            "']"
        );
        let video = tabContent.querySelector('video');
        video.pause();
        video.currentTime = 0;
        video.play();

        tabsImageTl
          .fromTo(
            tabContent,
            {
              alpha: 0,
            },
            { duration: 0.3, alpha: 1, ease: 'power3' }
          )
          .fromTo(
            tabContentActive,
            {
              alpha: 1,
            },
            { duration: 0.3, alpha: 0, ease: 'power3' },
            '-=0.3'
          );

        indicator();

        // Schedule the next opening
        listAnimationTimeout = setTimeout(changeTab, interval);
      });
    });

    indicator();

    function indicator() {
      let activeButton = document.querySelector(
        '.tabs-image__tabs__item.is-active'
      );
      if (!activeButton) return;
      let indicatorHeight = activeButton.offsetHeight;
      let indicatorOffset = activeButton.offsetTop;

      indicatorBar.style.height = indicatorHeight + 'px';
      indicatorBar.style.transform = 'translateY(' + indicatorOffset + 'px)';
      indicatorBar.style.opacity = '1';
    }

    if (tabs.length > 0) {
      indicator();

      window.addEventListener('resize', function () {
        indicator();
      });
    }

    listAnimationTimeout = setTimeout(changeTab, interval);
  }

  _imageCarousel() {
    let tabs = document.querySelectorAll('.image-carousel__left__list__item');
    let tabsImageTl = gsap.timeline({});
    let currentIndex = 0;
    let interval = 5000;
    let listAnimationTimeout;

    function changeTab() {
      let nextIndex = (currentIndex + 1) % tabs.length;
      if (!tabs[nextIndex]) return;
      if (document.hasFocus()) {
        tabs[nextIndex].click();
        currentIndex = nextIndex;
      } else {
        listAnimationTimeout = setTimeout(changeTab, interval);
      }
    }

    tabs.forEach(function (tab) {
      tab.addEventListener('click', function () {
        clearTimeout(listAnimationTimeout);
        if (this.classList.contains('is-active')) {
          listAnimationTimeout = setTimeout(changeTab, interval);
          return;
        }
        let tabContentActive = document.querySelector(
          ".image-carousel__right__item[data-tab*='" +
            document
              .querySelector('.image-carousel__left__list__item.is-active')
              .getAttribute('data-tab-id') +
            "']"
        );
        let nextIndex =
          (Number(this.getAttribute("data-tab-id")) - 1) % tabs.length;
        currentIndex = nextIndex;

        // Remove and add 'is-active' class
        tabs.forEach(function (otherTab) {
          otherTab.classList.remove('is-active');
        });
        this.classList.add('is-active');

        let tabContent = document.querySelector(
          ".image-carousel__right__item[data-tab*='" +
            this.getAttribute('data-tab-id') +
            "']"
        );
        let video = tabContent.querySelector('video');
        video.pause();
        video.currentTime = 0;
        video.play();

        tabsImageTl
          .fromTo(
            tabContent,
            {
              alpha: 0,
            },
            { duration: 0.3, alpha: 1, ease: 'power3' }
          )
          .fromTo(
            tabContentActive,
            {
              alpha: 1,
            },
            { duration: 0.3, alpha: 0, ease: 'power3' },
            '-=0.3'
          );

        // Schedule the next opening
        listAnimationTimeout = setTimeout(changeTab, interval);
      });
    });

    listAnimationTimeout = setTimeout(changeTab, interval);
  }
}
